import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import "../assets/css/table.css";
import apiFunctions from "../apiKit/api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

import {
    Button, ButtonGroup, Card, CardContent, IconButton,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Tooltip
} from "@mui/material";
import Swal from "sweetalert2";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
const PostTable = ({ creatorId, creatorName }) => {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [count, setCount] = useState(0);

    const columns = [
        {
            field: 'ano',
            disableColumnMenu: true,
            sortable: false,
            headerName: 'S.no',
            // flex: 1,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.api?.getSortedRowIds ?
                    params.api.getSortedRowIds().indexOf(params.id) + 1 : "Falling Back";
                return params.rowIndex + 1;
            }
        },
        {
            field: 'title', disableColumnMenu: true,
            sortable: false, headerName: 'Title', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'category', disableColumnMenu: true,
            sortable: false, headerName: 'Category', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'tier', disableColumnMenu: true,
            sortable: false, headerName: 'Tier', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        // {
        //     field: 'likes', disableColumnMenu: true,
        //     sortable: false, headerName: 'Likes', flex: 1, resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        // },
        // {
        //     field: 'comments', disableColumnMenu: true,
        //     sortable: false, headerName: 'Comments', flex: 1, resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        // },
        {
            field: 'postDate', disableColumnMenu: true,
            sortable: false, headerName: 'Post Date', flex: 1, resizable: false,
            headerAlign: 'center',
            align: 'center',
        },
        // {
        //     field: 'coverimage',
        //     headerName: 'Cover Image',
        //     flex: 1,
        //     disableColumnMenu: true,
        //     sortable: false,
        //     resizable: false,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: (params) => (
        //         <img
        //             src={params.value}
        //             alt="Cover"
        //             style={{ width: '100%', height: 'auto' }}
        //         />
        //     )
        // },
        {
            field: 'Action',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            headerName: 'Action',
            // renderCell: (params) => (
            //     <ButtonGroup>
            //         <IconButton onClick={() => handleViewOpen(params.row)}>
            //             <VisibilityIcon />
            //         </IconButton>
            //         <IconButton onClick={() => handleDelete(params.row?.id)}>
            //             <DeleteIcon />
            //         </IconButton>
            //     </ButtonGroup>
            // )
            renderCell: (params) => {
                const report = params?.row?.reportStatus;
                return (
                    <ButtonGroup>
                        <IconButton onClick={() => handleViewOpen(params.row)}>
                            <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(params.row?.id)}>
                            <DeleteIcon />
                        </IconButton>
                        {report === true ?
                            <Tooltip title="This Post were reported by user" placement="top">
                                <IconButton color="primary"><InfoIcon /></IconButton>
                            </Tooltip> : <span></span>}
                    </ButtonGroup>
                );
            }
        }
    ];

    // Fetch data with pagination
    const fetchData = async (pageNo) => {
        setLoading(true);
        try {
            const json = { "id": creatorId };
            const res = await apiFunctions.getCreatorPosts(pageNo, json);
            if (res.status === 200) {
                if (res.data?.data !== '') {
                    console.log("resssss", res.data.data);
                    setCount(res?.data?.datacount);
                    const formattedRows = res.data?.data?.map((item) => ({
                        id: item._id,
                        title: item.title,
                        category: item.category,
                        tier: item.tier,
                        likes: item.likedcount,
                        comments: item.commentcount,
                        postDate: new Date(item.createdAt).toLocaleDateString(),
                        coverimage: item.coverimage,
                        post: item.post
                    }));
                    setRows(formattedRows);
                    setRowCount(res.datacount);
                    setIsLastPage(res.isLast);
                    if (res.data?.data?.length < 10) {
                        setIsLastPage(true);
                    }
                } else {
                    setRows([]);
                    setRowCount(0);
                    setIsLastPage(true);
                }
            } else {
                setRows([]);
                setRowCount(0);
                setIsLastPage(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page);
    }, [page]);

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleDelete = (creatorPostId) => {
        Swal.fire({
            text: "Are you sure you want to delete this Post?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deletCreatorPosts(creatorPostId).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        fetchData(page);
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
            }
        });
    };

    return (
        <div>
            <div className="text-start d-flex mt-2">
                <h6 className="fw-bolder mr-2">Creator Name : </h6><h6 className="ms-2 fw-700"> {creatorName}</h6>
            </div>
            <div className="text-start d-flex">
                <h6 className="fw-bolder mr-2">No.of Posts : </h6><h6 className="ms-2 fw-700"> {count}</h6>
            </div>
            <Card elevation={4} className="p-0 mt-3">
                <CardContent className="p-0">

                    <div className="table-container">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row.id}
                            loading={loading}
                            disableColumnMenu
                            rowCount={rowCount}
                            localeText={{
                                noRowsLabel: 'No data found',
                            }}
                            autoHeight
                            pagination={false}
                            hideFooter={true}
                        />


                    </div>
                    {/* Custom Pagination */}
                    <div className="pagination">
                        <IconButton
                            disabled={page === 1}
                            onClick={() => setPage(page - 1)}
                        >
                            <KeyboardArrowLeftOutlinedIcon />
                        </IconButton>
                        <span>Page {page}</span>
                        <IconButton
                            disabled={isLastPage}
                            onClick={() => setPage(page + 1)}
                        >
                            <KeyboardArrowRightOutlinedIcon />
                        </IconButton>
                    </div>
                </CardContent>
            </Card>

            {/* View Modal */}
            <Dialog open={isViewOpen} onClose={handleViewClose} fullWidth maxWidth="sm">
                <DialogTitle>Creator Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td><strong>ID:</strong></td>
                                <td>{selectedRow?.id}</td>
                            </tr>
                            <tr>
                                <td><strong>Title:</strong></td>
                                <td>{selectedRow?.title}</td>
                            </tr>
                            <tr>
                                <td><strong>Category:</strong></td>
                                <td>{selectedRow?.category || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Tier:</strong></td>
                                <td>{selectedRow?.tier || "-"}</td>
                            </tr>
                            {/* <tr>
                                <td><strong>Likes:</strong></td>
                                <td>{selectedRow?.likes || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Comments:</strong></td>
                                <td>{selectedRow?.comments || "-"}</td>
                            </tr> */}
                            <tr>
                                <td><strong>Post Date:</strong></td>
                                <td>{selectedRow?.postDate || "-"}</td>
                            </tr>
                            <tr>
                                <td><strong>Post:</strong></td>
                                <td>
                                    {selectedRow?.post ? (
                                        <video controls style={{ width: "270px", height: "auto" }}>
                                            <source src={selectedRow?.post} type="video/mp4" />
                                        </video>
                                    ) : (
                                        <img style={{ width: "270px", height: "auto" }} src={selectedRow?.coverimage} alt="demo" />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default PostTable;
