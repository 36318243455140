import React, { useState, useEffect } from "react";
import "../../../src/custom.css";
import axios from "axios";
import { appConstants } from "../../apiKit/appConstant";

const ShowTerms = () => {
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        axios.get(`${appConstants.baseUrl}terms`).then((res) => {
            if (res.status === 200) {
                setPrivacyContent(res.data?.data[0]?.content);
            } else {
                setPrivacyContent("");
            }
        })
    }
    const [privacyContent, setPrivacyContent] = useState("");
    return (
        <div className="p-3">
            <div
                dangerouslySetInnerHTML={{ __html: privacyContent }}
            />
        </div>
    )
}
export default ShowTerms;