import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar";
import { Button, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../../../src/custom.css";
import Table from "../../components/Table";
import { useSelector } from 'react-redux';
import ReduxComponent from "../../components/reduxData";
import PostTable from "../../components/postTable";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
const TopCreators = () => {
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter] = useState({ username: '', email: '', phone: '' });
    const [filteredRows, setFilteredRows] = useState([]);
    const { topCreatordata } = useSelector((state) => state.api);
    const [IsviewAll, setIsviewAll] = useState(false);

    useEffect(() => {
        console.log("Top creator data", topCreatordata);
        setIsviewAll(false);
    }, [topCreatordata]);


    useEffect(() => {
        // dispatch(fetchAPIs());
        const newFilteredRows = topCreatordata?.map(row => ({
            ...row,
            username: row.username || null,
            email: row.email || null,
            phone: row.phone || null
        })).filter(row => {
            return (
                (filter.username === '' || row.username?.toLowerCase().includes(filter.username.toLowerCase())) &&
                (filter.email === '' || row.email?.toLowerCase().includes(filter.email.toLowerCase())) &&
                (filter.phone === '' || row.phone?.toString().includes(filter.phone))
            );
        }) || [];
        setFilteredRows(newFilteredRows);
    }, [topCreatordata]);




    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };


    const columns = [
        {
            field: 'ano',
            disableColumnMenu: true,
            sortable: false,
            headerName: 'S.no',
            flex: 1,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.api?.getSortedRowIds ?
                    params.api.getSortedRowIds().indexOf(params.id) + 1 : "Falling Back";
                return params.rowIndex + 1;
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            sortable: false,
            field: 'phone',
            flex: 1,
            headerName: 'Phone',
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'TotalPosts',
            flex: 1,
            headerName: 'Posts',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                // const creater_status = params.row.creater_status;
                return (
                    <ButtonGroup>
                        {/* <Switch checked={creater_status === 1} disabled={creater_status === 0}
                            onChange={() => handleCreatorSwitchChange(params.row)}></Switch> */}
                        <IconButton onClick={() => handleViewOpen(params.row)}>
                            <VisibilityIcon />
                        </IconButton>
                    </ButtonGroup>
                );
            }
        }
    ];

    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    {/* Page Contents */}
                    <div>
                        {IsviewAll ?
                            // creator Posts
                            <div className="row p-3 mt-3 main">
                                <div className="text-start">
                                    {/* <h5 className="fw-bold">{selectedRow?.username ? selectedRow?.username : selectedRow?.name} Posts</h5>
                                            <br /> */}
                                    <Button className="btn mb-2" startIcon={<KeyboardBackspaceOutlinedIcon />} onClick={() => setIsviewAll(false)} >Go Back</Button>
                                </div>
                                <PostTable creatorId={selectedRow?.userId} creatorName={selectedRow?.username ? selectedRow?.username : selectedRow?.name} />
                            </div>
                            :
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="text-start mb-3">
                                        <h4 className="fw-bold">Top Creators</h4>
                                    </div>
                                    <Table pageSize={10} rows={filteredRows} columns={columns} />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleViewClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle>Creator Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td> <p><strong>ID:</strong></p></td>
                                <td> <p>{selectedRow?._id}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Name:</strong></p></td>
                                <td> <p>{selectedRow?.name ? selectedRow?.name : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Username:</strong></p></td>
                                <td> <p>{selectedRow?.username ? selectedRow?.username : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Email:</strong></p></td>
                                <td> <p>{selectedRow?.email ? selectedRow?.email : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>No.of.Posts:</strong></p></td>
                                <td> <p>{selectedRow?.TotalPosts ? selectedRow?.TotalPosts : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Phone:</strong></p></td>
                                <td> <p>{selectedRow?.phone ? selectedRow?.phone : "-"}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>DOB:</strong></p></td>
                                <td> <p>{selectedRow?.DOB ? selectedRow?.DOB : "-"}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <ReduxComponent />
        </>
    );
};

export default TopCreators;
