import method from "./method";

const apiFunctions = {

    loginApi: (payload) => method.post("login", payload),
    logoutApi: (payload) => method.post("logout", payload),
    changepasswordApi: (payload) => method.post("changepassword", payload),

    //dashboard
    dashboardApi: () => method.get("dashboard"),
    //users
    getallUserApi: (payload) => method.get("getallusers"),
    //Invite-requests
    getallInviteUserApi: (payload) => method.get("pendinglist"),
    userChangestatus: (payload) => method.post("change_status", payload),
    userApprove: (payload) => method.post("approve", payload),
    //creators
    getcreatorsApi: (payload) => method.get("creatorlist"),
    creatorChangestatus: (payload) => method.post("creater_status", payload),

    //faq
    getallFaq: () => method.get("getfaq"),
    createFaq: (payload) => method.post("createfaq", payload),
    editFaq: (payload, id) => method.post(`editfaq/${id}`, payload),
    deleteFaq: (id) => method.post(`deletefaq/${id}`),

    //terms and conditions
    getTermsApi: () => method.get("getterms"),
    updatetermsApi: (payload, id) => method.post(`update_terms/${id}`, payload),

    //privacy
    getPrivacy: () => method.get("getprivacy"),
    updatePrivacy: (payload, id) => method.post(`update_privacy/${id}`, payload),

    //settings
    getSettings: () => method.get("getsetting"),
    updateSettings: (payload) => method.post("update_settings", payload),

    //Interests
    getallInterestsApi: () => method.get("get_interest"),
    updateInterestApi: (payload, id) => method.post(`edit_interest/${id}`, payload),
    addNewInterestApi: (payload) => method.post("add_interest", payload),
    deleteInterestApi: (id) => method.post(`delete_interest/${id}`),

    //Notifications
    getallNotificationsApi: () => method.get("notificationslist"),
    editNotificationApi: (json,id) => method.post(`edit_notification/${id}`,json),

    //Reports
    getVideoReports: () => method.get("reported_post"),
    getUserReports: () => method.get("report_problem"),
    getNotifyReports: () => method.get("notificationreport"),

    //getcreatorsPosts
    getCreatorPosts: (pageNo, payload) => method.post(`creater_post?pageno=${pageNo}`, payload),
    deletCreatorPosts: (id) => method.post(`deletevideo`, { "id": id }),
    // M3
    //creators
    getTopcreatorsApi: () => method.get("topcreators"),
    //user-statistics
    getChartYearsApi: () => method.get("useryearlist"), //charts-year
    getChartDatasApi: (year) => method.get(`userstatistics?year=${year}`), //charts-data-based-year
    //video-statistics
    getChartVideoYearsApi: () => method.get("videoyearlist"), //charts-year
    getChartVideoDatasApi: (year) => method.get(`videostatistics?year=${year}`), //charts-data-based-year
    //Payment-statistics
    getChartPaymentYearsApi: () => method.get("paymentyearlist"), //charts-year
    getChartPaymentDatasApi: (year) => method.get(`paymentstatistics?year=${year}`), //charts-data-based-year
    //getAllPosts
    getAllPosts: (pageNo) => method.get(`view_videos?pageno=${pageNo}`),
    deletAdminPosts: (id) => method.post(`delete_post/${id}`),
    //getPayments and payouts
    getAllPaymenthistory: (pageNo) => method.get(`paymenthistory?pageno=${pageNo}`),
    getAllPayouthistory: (pageNo) => method.get(`payouthistory?pageno=${pageNo}`),
    //notification report



}

export default apiFunctions;