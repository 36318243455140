import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, ButtonGroup, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "../../src/custom.css";
import Table from "../components/Table";
import Swal from "sweetalert2";
import Loader from "../components/Loader";
import Dropdown from "../components/dropdown";
import { useDispatch, useSelector } from 'react-redux';
import apiFunctions from "../apiKit/api";
import { fetchAPIs } from "../redux/slice/slice";
import ReduxComponent from "../components/reduxData";
import { Add, Delete } from "@mui/icons-material";

const Interest = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [data, setData] = useState([]);
    const { Interestdata } = useSelector((state) => state.api);
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            // dispatch(fetchAPIs());
        }, 2000);
        console.log("redux Interest data", Interestdata);
        setData(Interestdata);
    }, [Interestdata]);


    const handleOpen = () => {
        setIsopen(!isOpen);
    };

    const handleAddOpen = (row) => {
        setSelectedRow(row);
        setIsAddOpen(true);
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleClose = () => {
        setIsViewOpen(false);
        setIsAddOpen(false);
        setIsEditOpen(false);
        setSelectedRow(null);
    };

    const handleAddSave = (e) => {
        e.preventDefault();
        // Logic to save the edited data
        setIsAddOpen(false);
        var json = {
            "category": selectedRow?.category
        }
        apiFunctions.addNewInterestApi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
        setSelectedRow(null);
    };

    const handleEditSave = () => {
        // Logic to save the edited data
        setIsEditOpen(false);
        var json = {
            "category": selectedRow?.category
        }
        apiFunctions.updateInterestApi(json, selectedRow?._id).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });
        setSelectedRow(null);
    };

    const handleDelete = (data) => {
        Swal.fire({
            // title: 'Are you sure?',
            text: 'Are you sure want to delete?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deleteInterestApi(data?._id).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        dispatch(fetchAPIs());
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
                setSelectedRow(null);
            }
        });
    };


    const columns = [
        {
            field: 'ano',
            disableColumnMenu: true,
            sortable: false,
            headerName: 'S.no',
            flex: 1,
            resizable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.api?.getSortedRowIds ?
                    params.api.getSortedRowIds().indexOf(params.id) + 1 : "Falling Back";
                return params.rowIndex + 1;
            }
        },
        {
            field: 'category',
            headerName: 'Interest name',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.value ? params.value : '-'
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                if (!params.value) return '-';

                const date = new Date(params.value);
                const options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                };

                return date.toLocaleString('en-US', options);
            }
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                        <IconButton onClick={() => handleDelete(params.row)}><Delete /></IconButton>
                    </ButtonGroup >
                );
            }
        },
    ];


    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="row">
                            <div className="d-flex w-100 justify">
                                <IconButton className="web-btn" onClick={handleOpen}>
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row p-3 mt-3 main">
                                <div className="col-12">
                                    {/* <Card>
                                        <CardContent> */}
                                    <div className="d-flex justify mb-3">
                                        <h4 className="fw-bold">Interests</h4>
                                        <Button className="btn" endIcon={<Add />} onClick={handleAddOpen} >Add New</Button>
                                    </div>
                                    <Table rows={data} columns={columns} />
                                    {/* </CardContent>
                                    </Card> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            {/* View Modal */}
            <Dialog className="p-3" open={isViewOpen} onClose={handleClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle>Interest Detail</DialogTitle>
                <DialogContent>
                    <table className="table table">
                        <tbody>
                            <tr>
                                <td> <p><strong>ID:</strong></p></td>
                                <td> <p>{selectedRow?._id}</p></td>
                            </tr>
                            <tr>
                                <td> <p><strong>Interest name:</strong></p></td>
                                <td> <p>{selectedRow?.category
                                    ? selectedRow?.category
                                    : "-"}</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Created at:</strong></p></td>
                                <td>
                                    <p>
                                        {selectedRow?.createdAt
                                            ? new Date(selectedRow.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })
                                            : "-"
                                        }
                                    </p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="btn" onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleClose}>
                <DialogTitle>Edit Interest</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Interest Name"
                        type="text"
                        fullWidth
                        value={selectedRow?.category || ''}
                        onChange={(e) => setSelectedRow({ ...selectedRow, category: e.target.value })}
                    />
                </DialogContent>
                <DialogActions className="p-3">
                    <Button variant="contained" className="grey-btn" onClick={handleClose}>Cancel</Button>
                    <Button className="btn" variant="contained" onClick={handleEditSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Add Modal */}
            <Dialog open={isAddOpen} onClose={handleClose}>
                <form onSubmit={handleAddSave}>
                    <DialogTitle>Add new interest</DialogTitle>
                    <DialogContent>
                        <TextField
                            margin="dense"
                            label="Add new interest"
                            type="text"
                            fullWidth
                            value={selectedRow?.category || ''}
                            onChange={(e) => setSelectedRow({ ...selectedRow, category: e.target.value })}
                            required
                        />
                    </DialogContent>
                    <DialogActions className="p-3">
                        <Button variant="contained" className="grey-btn" onClick={handleClose}>Cancel</Button>
                        <Button className="btn" type="submit" variant="contained" >Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <ReduxComponent />
        </>
    );
};

export default Interest;
