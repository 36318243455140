import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import Dropdown from "../components/dropdown";
import { pageRoutes } from '../config/route';
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded';
import apiFunctions from "../apiKit/api";
import ReduxComponent from "../components/reduxData";
import TopCreators from "./topcreators/topcreator";
import { AgCharts } from "ag-charts-react";
import InterestsSharpIcon from '@mui/icons-material/InterestsSharp';

const Dashboard = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [userCount, setUserCount] = useState(0);
    const [creatorCount, setCreatorCount] = useState(0);
    const [InterestCount, setInterestCount] = useState(0);
    const [options, setOptions] = useState({});

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const fetchDashCounts = useCallback(async () => {
        apiFunctions.dashboardApi().then((res) => {
            if (res.status === 200) {
                console.log("count", res.data.data);
                setUserCount(res?.data?.data?.user_count);
                setCreatorCount(res?.data?.data?.creater_count);
                setInterestCount(res?.data?.data?.interest_count);
            } else {
                console.log("Failed to fetch banners");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const getUserdata = () => {
        apiFunctions.getChartDatasApi("").then((res) => {
            if (res.status === 200) {
                console.log("user charts", res.data.data);

                setOptions({
                    title: {
                        text: "Users by Month",
                    },
                    data: res?.data?.data?.[0]?.month,
                    series: [
                        {
                            type: "bar",
                            xKey: "month",
                            xName: "Month",
                            yKey: "userCount",
                            yName: "Users",
                            tooltip: {
                                enabled: true,
                                renderer: (params) => {
                                    const xValue = params?.datum?.month || "Unknown";
                                    const yValue = params?.datum?.userCount  || 0;
                                    return {
                                        content: `${xValue}: ${Math.round(yValue)}`
                                    };
                                },
                            },
                        },
                    ],
                });
            } else {
                console.log("Failed to fetch user charts");
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        fetchDashCounts();
        getUserdata();
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [fetchDashCounts])




    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className='row'>
                            <div className="d-flex w-100 justify sticky-top">
                                <IconButton className="web-btn" onClick={handleOpen} >
                                    <MenuIcon />
                                </IconButton>
                                <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                    <MenuIcon />
                                </IconButton>
                                <div className="logout_dropdown">
                                    {/* <Logout /> */}
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <>
                                <div className="row p-lg-5 p-3 mt-lg-4 mt-3 main">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.users} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Users</h5>
                                                    <div className="d-flex justify">
                                                        <PeopleRoundedIcon color="white" />
                                                        <h3 className="dash_count_text">{userCount}</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.creators} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Creators</h5>
                                                    <div className="d-flex justify">
                                                        <AlignHorizontalLeftRoundedIcon />
                                                        <h3 className="dash_count_text">{creatorCount}</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <Link to={pageRoutes.interests} style={{ textDecoration: "none", color: "white" }} >
                                                <CardContent>
                                                    <h5 className="dash_count_text">Interests</h5>
                                                    <div className="d-flex justify">
                                                        <InterestsSharpIcon />
                                                        <h3 className="dash_count_text">{InterestCount}</h3>
                                                    </div>
                                                </CardContent>
                                            </Link>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row p-lg-5 p-3 main">
                                    <TopCreators />
                                </div>
                                <div className="row p-lg-5 p-3 main">
                                    <Card className="mt-3">
                                        <CardContent>
                                            <h5><b>Users</b></h5>
                                            <AgCharts options={options} />
                                        </CardContent>
                                    </Card>
                                </div>
                            </>
                        }

                    </div>
                </div>

            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header">
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'white' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Dashboard;